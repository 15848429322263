<template>
  <div class="agencydetails">
    <div class="agencydetails_mybreadcrumb">
      当前位置： <app-mybreadcrumb />
    </div>

    <section class="agencydetails_comp">
      <div class="agencydetails_pic_box1">
        <img :src="infoDetail.finance_trade" class="agencydetails_pic" />
      </div>

      <div class="agencydetails_rig">
        <div class="agencydetails_rig_title" :title="infoDetail.name">
          {{ infoDetail.name }}
        </div>
        <div class="agencydetails_rig_content app_line2">
          {{ infoDetail.introduce }}
        </div>
      </div>
    </section>

    <section class="agencydetails_information">
      <div class="agencydetails_information_item">
        <div class="agencydetails_information_num">
          <span style="margin-right: 3px;">{{
            cnt.pro_num ? cnt.pro_num : 0
          }}</span
          >个
        </div>
        <div class="agencydetails_information_bm">
          <img src="../../assets/image/2.png" class="agencydetails_icon" />
          <div class="agencydetails_information_font">产品数量</div>
        </div>
      </div>

      <div class="agencydetails_information_item">
        <div class="agencydetails_information_num">
          <span style="margin-right: 3px;">{{
            cnt.succ_num ? cnt.succ_num : 0
          }}</span
          >次
        </div>
        <div class="agencydetails_information_bm">
          <img src="../../assets/image/5.png" class="agencydetails_icon" />
          <div class="agencydetails_information_font">放款笔数</div>
        </div>
      </div>

      <div class="agencydetails_information_item">
        <div class="agencydetails_information_num">
          <span style="margin-right: 3px;">{{
            cnt.succ_money ? cnt.succ_money : 0
          }}</span
          >万
        </div>
        <div class="agencydetails_information_bm">
          <img src="../../assets/image/14.png" class="agencydetails_icon" />
          <div class="agencydetails_information_font">放款金额</div>
        </div>
      </div>

      <div class="agencydetails_information_item">
        <div class="agencydetails_information_num">
          <span style="margin-right: 3px;">{{
            cnt.wait_num ? cnt.wait_num : 0
          }}</span
          >笔
        </div>
        <div class="agencydetails_information_bm">
          <img src="../../assets/image/4.png" class="agencydetails_icon" />
          <div class="agencydetails_information_font">正在对接</div>
        </div>
      </div>
    </section>

    <section class="agencydetails_cooperation">
      <div class="agencydetails_cooperation_title">机构产品</div>

      <div class="agencydetails_list">
        <div
          class="agencydetails_list_good"
          v-for="(item, index) in listsItem"
          :key="index"
        >
          <div class="agencydetails_pic_box2">
            <img :src="item.gallery" class="agencydetails_pic" />
          </div>

          <div class="agencydetails_good_rig">
            <div class="agencydetails_good_top">
              <div class="agencydetails_good_name" :title="item.name">
                {{ item.name }}
                <span v-if="item.is_policy == 0">政策型产品</span>
              </div>

              <div
                class="agencydetails_good_detail"
                @click="toAgencyDetails(item.id)"
              >
                查看详情
              </div>
            </div>

            <div class="agencydetails_good_bm">
              <div class="agencydetails_good_message">
                <div class="agencydetails_good_label">
                  融资额度：
                  <span>{{ item.min_quota }}~{{ item.max_quota }}万</span>
                </div>
                <div class="agencydetails_good_label">
                  利率：<span>{{ item.min_rate }}%~{{ item.max_rate }}%</span>
                </div>
                <div class="agencydetails_good_label">
                  适用地区： <span>{{ item.region_name }}</span>
                </div>
                <!-- <div class="agencydetails_good_label">抵款期限： <span>{{item.min_credit}}~{{item.max_credit}}月</span></div> -->
                <div class="agencydetails_good_label">
                  担保： <span> {{ item.guar_name }}</span>
                </div>
              </div>

              <div
                class="agencydetails_good_apply"
                @click="towebapply(item.id)"
              >
                立即申请
              </div>
            </div>
          </div>
        </div>

        <!-- 分页 -->
        <div class="agencydetails_reportscreen">
          <div class="agencydetails_footer">
            <div class="agencydetails_mar">
              <app-mypaginations
                :pagSize="paginations"
                :allData="agenList"
                @setHandleCurrent="handleCurrent"
                @setHandleSize="handleSize"
                @setPage="setPage"
                @setSize="setSize"
              />
            </div>
          </div>
        </div>

        <el-empty
          description="暂无数据"
          v-if="agenList.length == 0"
          style="width: 100%;"
        ></el-empty>
      </div>
    </section>
  </div>
</template>

<script>
import MyBreadcrumb from '@/components/MyBreadcrumb/mybreadcrumb';
import MyPaginations from '@/components/MyPaginations/mypaginations.vue';

export default {
  data() {
    return {
      paginations: {
        page_index: 1, // 当前位于哪页
        total: 10, // 总数
        page_size: 10, // 1页显示多少条
        page_sizes: [10, 20, 50, 90], //每页显示多少条
        layout: 'total, sizes, prev, pager, next, jumper', // 翻页属性
      },
      listsItem: [],
      agenList: [],
      infoDetail: {
        finance_trade: '',
        name: '',
        introduce: '',
      },
      count: 0,
      adopt: -1,
      nowTime: '',
      cnt: {},
      lastTime: '',
    };
  },
  components: {
    'app-mybreadcrumb': MyBreadcrumb,
    'app-mypaginations': MyPaginations,
  },
  methods: {
    toAgencyDetails(id) {
      console.log(id, 55);
      this.$router.replace({
        path: '/productsdetail',
        query: { ids: id, type: 3 },
      });
    },
    // 用户信息  0 审核  1 通过  2 不通过  3 没有认证
    getVali(item) {
      let data = { token: localStorage.eleToken ? localStorage.eleToken : '' };
      this.$post('/vali', data).then((res) => {
        if (res.data.status == 1) {
          this.adopt = res.data.result.adopt;

          if (res.data.result.type == 1) {
            if (this.adopt == 0) {
              this.$message({
                message: '实名认证审核中',
                type: 'info',
              });
            } else if (this.adopt == 1) {
              this.$router.push({
                path: '/webproductapply',
                query: { ids: item, type: 3 },
              });
            } else if (res.data.result.adopt == 2) {
              this.$confirm('实名认证不通过，是否重新提交？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
              })
                .then(() => {
                  // this.$router.push('/enterprisecertification')
                  location.href = 'http://sso.smeqh.cn:9000/realname/index';
                })
                .catch(() => {
                  this.$message({
                    type: 'info',
                    message: '已取消',
                  });
                });
            } else if (res.data.result.adopt == 3) {
              this.$confirm('还没有实名认证，是否前去完善？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
              })
                .then(() => {
                  //   this.$router.push('/enterprisecertification)
                  location.href = 'http://sso.smeqh.cn:9000/realname/index';
                })
                .catch(() => {
                  this.$message({
                    type: 'info',
                    message: '已取消',
                  });
                });
            } else {
              return false;
            }
          }

          if (res.data.result.type == 2) {
            // localStorage.clear()

            // this.$router.replace('/login')
            this.$message({
              message: '金融机构不能申请产品',
              type: 'error',
            });
          }
        }
      });
    },
    towebapply(item) {
      this.nowTime = new Date();

      if (this.nowTime - this.lastTime > 1500) {
        this.getVali(item);

        this.lastTime = this.nowTime;
      }
    },
    getData() {
      let data = { id: this.$route.query.ids };

      this.$post('/finance_detail', data).then((res) => {
        if (!res.data.result) {
          this.agenList = [];
          this.count = 0;
        } else {
          this.infoDetail = !res.data.result.info ? {} : res.data.result.info;
          this.agenList =
            res.data.result.product.length == 0 ? [] : res.data.result.product;
          this.cnt = !res.data.result.cnt ? {} : res.data.result.cnt;
          this.count = res.data.count;
        }

        this.resetPaginations();
      });
    },
    setPage(data) {
      this.paginations.page_index = data;

      this.getData();
    },
    setSize(data) {
      this.paginations.page_index = 1;
      this.paginations.page_size = data;

      this.getData();
    },
    handleCurrent(item) {
      this.listsItem = item;
    },
    handleSize(item) {
      this.listsItem = item;
    },
    //重置分页
    resetPaginations() {
      // 总页数
      this.paginations.total = this.count;
      // 设置默认分页数据
      this.listsItem = this.agenList.filter((item, index) => {
        return index < this.paginations.page_size;
      });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped>
@import './agencydetails.css';
</style>
